let cdnjs = {
    picturefill: "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js"
};

let $ = jQuery,
    doc = $(document),
    win = $(window),
    html = $("html"),
    body = $("body"),
    lang = html.attr('lang'),
    domain = "https://weild.devlogic.cz";

let swup;

window.lui = {
    layout: {},
    comp: {},
    part: {},
    lib: {}
};

let osScrollMain;

if (window.matchMedia('(display-mode: standalone)').matches) {
    doc.on('contextmenu', function (e) {
        if (e.target.nodeName !== "A" && !$(e.target).closest("a").length) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            e.stopPropagation();

            if ($(e.target).closest(".part_ui_dropdown").length) {
                return false;
            }

            let href;

            if ($(e.target).closest("a").length) {
                href = $(e.target).closest("a").attr("href");
            } else {
                href = $(e.target).attr("href");
            }

            if ($(".tippy--invisible").length) {
                $(".tippy--invisible").remove();
                tippy.hideAll({ duration: 0 });
            }

            body.append(`<div class="tippy--invisible" style="top:${e.originalEvent.clientY}px; left:${e.originalEvent.clientX}px"></div>`);

            tippy($(".tippy--invisible")[0], {
                content: `
                <div class="part_ui_dropdown">
                    <ul class="wrp_ui_list">
                        <li>
                            <a href="${href}" target="_blank" rel="noopener" data-no-swup>
                                <span class="icon icon--earth"></span>
                                <span>Otevřít odkaz v novém okně</span>
                            </a>
                        </li>
                        <li>
                            <button id="tippy--copy" data-copy-href="${href}">
                                <span class="icon icon--copy"></span>
                                <span>Kopírovat adresu odkazu</span>
                            </button>
                        </li>
                    </ul>
                </div>
            `,
                theme: 'light-border',
                placement: "bottom-start",
                interactive: true,
                arrow: false,
                arrowType: 'round',
                animation: "scale",
                flip: true,
                inertia: true,
                distance: 32,
                onShown: function() {
                    let id = $("#tippy--copy");

                    id.on("click", function(){
                        let temp_input = document.createElement('INPUT');

                        body[0].appendChild(temp_input);
                        temp_input.setAttribute('value', id.data("copy-href"));
                        temp_input.select();
                        document.execCommand('copy');
                        body[0].removeChild(temp_input);

                        tippy.hideAll({ duration: 300 });
                    });
                },
                onHide: function(instance){
                    $(".tippy--invisible").remove();
                }
            }).show();
        }
    });
}

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function() {
        if (!html.hasClass("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function(e) {
                console.error('Error during service worker registration:', e);
            });

            let appInstalled = false;

            window.addEventListener('appinstalled', (evt) => {
                appInstalled = true;

                doc.on('contextmenu', function (e) {
                    if (e.target.nodeName !== "A" && !$(e.target).closest("a").length) {
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        e.preventDefault();
                        e.stopPropagation();
                        console.log(e);
                    }
                });
            });

            if (appInstalled === false && localStorage.getItem("pwaState") === "accepted") {
                localStorage.removeItem("pwaState");
            }

            if (localStorage.getItem("pwaState") === null && document.documentElement.classList.toString().match(/(android|ios|iphone|ipad)/) === null) {
                let deferredPrompt;

                window.addEventListener('beforeinstallprompt', (e) => {
                    e.preventDefault();

                    setTimeout(function () {
                        body.append(`
                            <div class="part_prompt state--active is--animate">
                                <div class="wrp_part_text">
                                    <div class="part_ui_wsw">
                                        <p>Přidejte si Weild jako aplikaci do vašeho zařízení a mějte ho vždy při ruce.</p>
                                    </div>
                                </div>
                                <div class="wrp_part_btn row--flex row--h-8">
                                    <div class="col">
                                        <button class="part_ui_btn" aria-label="Instalovat jako aplikaci" data-pwa-approve><span>Instalovat jako aplikaci</span></button>
                                    </div>
                                    <div class="col">
                                        <button class="part_ui_btn type--outline display--auto" aria-label="Neinstalovat aplikaci" data-pwa-disable><span>Ne děkuji</span></button>
                                    </div>
                                </div>
                            </div>
                        `);

                        deferredPrompt = e;
                    },1500);
                });

                doc.on("click", "[data-pwa-approve]", function(){
                    let prompt = $(".part_prompt");
                    deferredPrompt.prompt();

                    deferredPrompt.userChoice.then((choiceResult) => {
                        if (choiceResult.outcome === 'accepted') {

                            prompt.removeClass("is--animate");
                            localStorage.setItem("pwaState", "accepted");

                            setTimeout(function () {
                                prompt.remove();
                            },1000);

                            deferredPrompt = null;
                        }
                    });
                });

                doc.on("click", "[data-pwa-disable]", function(){
                    let prompt = $(".part_prompt");

                    prompt.removeClass("is--animate");
                    localStorage.setItem("pwaState", "disabled");

                    setTimeout(function () {
                        prompt.remove();
                    },1000);
                });
            }
        } else {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                if (registrations.length > 0) {
                    for(let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

$(function() {
    if (document.documentElement.classList.toString().match(/(android|ios|iphone|ipad)/) === null) {
        if (typeof OverlayScrollbars !== "undefined") {
            osScrollMain = OverlayScrollbars(document.querySelectorAll("body"), {
                overflowBehavior : {
                    x : "hidden",
                    y : "scroll"
                },
                scrollbars : {
                    autoHide         : "move",
                    autoHideDelay    : 800
                }
            });
        }
    }
});

function darkTheme(state) {
    let css = document.getElementById("layout_css");
    let href;

    if (state) {
        href = css.href.replace("light", "dark");
        html.addClass("theme-dark");
        $('meta[name="theme-color"]').attr("content","#2f3247");
        // ajax post na server s preferencí dark theme
    } else {
        href = css.href.replace("dark", "light");
        html.removeClass("theme-dark");
        $('meta[name="theme-color"]').attr("content","#fff");
        // ajax post na server s preferencí light theme
    }

    if (document.getElementById("layout_manifest") != null) {
        let manifest = JSON.parse(document.getElementById("layout_manifest").innerHTML);

        let name = href.substring(href.lastIndexOf("/")+1, href.length);

        if (typeof manifest[`${name.substring(0, name.indexOf("."))}.css`] !== "undefined") {
            css.href = href.replace(name, manifest[`${name.substring(0, name.indexOf("."))}.css`]);
        }
    } else {
        css.href = href;
    }
}

if (window.matchMedia("(prefers-color-scheme: dark)").matches === true && localStorage.getItem("darkTheme") === null) {
    localStorage.setItem("darkTheme", "enabled");
    $('meta[name="theme-color"]').attr("content","#2f3247");
    // ajax post na server s preferencí dark theme
} else if (localStorage.getItem("darkTheme") !== "disabled") {
    $('meta[name="theme-color"]').attr("content","#2f3247");
}

try {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener("change", function(e) {
        if (localStorage.getItem("darkTheme") !== "disabled") {
            darkTheme(e.matches);
        }
    });
} catch {}

doc.on("change", `[data-toggle="darkmode"]`, function(){
    if ($(this).prop("checked") === true) {
        localStorage.setItem("darkTheme", "enabled");
        darkTheme(true);
        // ajax post na server s preferencí dark theme
    } else {
        localStorage.setItem("darkTheme", "disabled");
        darkTheme(false);
        // ajax post na server s preferencí light theme
    }
});

(function preloadPage() {
    if (document.body.classList.contains("body--preload")) {
        cssLoaded(function(){
            document.body.classList.remove("body--preload");
            setTimeout(function () {
                document.body.classList.add("body--loaded");
            }, 300);
        });
    }
})();

(function preloadFont() {
    if (typeof WebFontConfig === "undefined") {
        if (typeof document.fonts !== "undefined") {
            document.fonts.ready.then(function () {
                document.documentElement.classList.add("wf-active");
            });
            setTimeout(()=> {
                if(!document.documentElement.classList.contains("wf-active")) {
                    document.documentElement.classList.add("wf-active");
                }
            },500);
        } else {
            document.documentElement.classList.add("wf-active");
        }
    }
})();

(function touchDetection() {
    let hasHoverClass = false;
    let container = document.documentElement;
    let lastTouchTime = 0;

    function enableHover() {
        if (new Date() - lastTouchTime < 500) return;
        if (hasHoverClass) return;

        container.className += ' no-touch';
        hasHoverClass = true;
    }

    function disableHover() {
        if (!hasHoverClass) return;

        container.className = container.className.replace(' no-touch', '');
        hasHoverClass = false;
    }

    function updateLastTouchTime() {
        lastTouchTime = new Date();
    }

    document.addEventListener('touchstart', updateLastTouchTime, true);
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);
})();;
function bodyLoaded(callback) {
    if (!document.body.classList.contains("body--loaded")) {
        let wait = setInterval(function(){
            if (document.body.classList.contains("body--loaded")) {
                clearInterval(wait);
                callback();
            }
        },1);
    } else {
        callback();
    }
};
function cssLoaded(callback) {
    if (document.querySelector("[data-loadcss]") !== null) {
        let wait = setInterval(function(){
            if (getComputedStyle(document.body)["margin-left"] === "0px") {
                clearInterval(wait);
                callback();
            }
        },1);
    } else {
        callback();
    }
};
jQuery.importScript = function( url, callback ) {
    if ($.importScript.used.indexOf(url) === -1) {
        $.importScript.used.push(url);
        return jQuery.ajax({
            dataType: "script",
            cache: true,
            url: url,
            async: false,
            success: function() {
                $.importScript.loaded.push(url);
                if (callback) {
                    callback();
                }
            },
            error: function(){
                $.importScript.used.splice($.importScript.used.indexOf(url), 1);
                return false;
            }
        });
    } else {
        if (callback) {
            let interval = setInterval(function(){
                if ($.importScript.used.indexOf(url) === -1) {
                    clearInterval(interval);
                    return false;
                }
                if ($.importScript.loaded.indexOf(url) !== -1) {
                    clearInterval(interval);
                    callback();
                }
            },1);
        }
    }

};

jQuery.importScript.used = [];
jQuery.importScript.loaded = [];
;
jQuery.lui = function(n,s,callback) {
    (function fn(name, selector) {
        let fns;
        let name_selector;

        if (typeof selector === "string") {
            name_selector = selector.split(" ").splice(-1)[0];

            if ($(selector).length) {
                fns = callback($(selector),{})
            }
        } else {
            if (selector.length) {
                fns = callback(selector,{})
            }
        }

        if (name.indexOf("lib") !== -1) {
            if (typeof window.lui["lib"][name] === "undefined") {
                window.lui["lib"][name] = fn;
                window.lui["lib"][name].selector = selector;
            }
        } else {
            if (typeof window.lui[name][name_selector] === "undefined") {
                window.lui[name][name_selector] = fn;
                window.lui[name][name_selector].selector = selector;
            }

            if (typeof fns !== "undefined") {
                window.lui[name][name_selector].fns = fns;
            }
        }
    })(n,s);
};;
$.fn.validateInput = function () {
    $(this).removeClass("state--invalid state--valid");
    if ($(this).find("input, textarea").val() !== "" && !$(this).find("input, textarea").filter("[readonly]").length) {
        if ($(this).find("input, textarea").is(":valid")) {
            $(this).addClass("state--valid");
        } else {
            if(!$(this).find("input, textarea").filter("[readonly]").length) {
                $(this).addClass("state--invalid");
            }
        }
    }
};;
$.fn.lui_ripple = function () {
    $(this).each(function(){
        if (!$(this).filter("[data-lib-ripple]").length) {
            $(this).attr("data-lib-ripple", "");
        }
    });

    doc.on("click", "[data-lib-ripple]", function(event){
        let surface = $(this);

        function fn_rippleInner(container) {
            if (container.children(".lib--ripple-inner").length === 0) {
                container.append("<div class='lib--ripple-inner'></div>");
            }

            let ink = container.children(".lib--ripple-inner");

            ink.removeClass("animated");

            if (!ink.height() && !ink.width()) {
                let d = Math.max(surface.outerWidth(), surface.outerHeight());
                ink.css({height: d, width: d});
            }

            let x, y;

            if (container === body) {
                x = event.clientX - container.offset().left - (ink.width() / 2);
                y = event.clientY - container.offset().top - (ink.height() / 2);
            } else {
                x = event.pageX - container.offset().left - (ink.width() / 2);
                y = event.pageY - container.offset().top - (ink.height() / 2);
            }

            ink.css({
                top: y + 'px',
                left: x + 'px',
            }).addClass("animated");
        }

        if (surface.data("lib-ripple") === "body") {
            fn_rippleInner(body);
        } else {
            fn_rippleInner(surface);
        }
    });
};;
function fn_lib_notification() {
    
    function fn_lib_notification_show(data) {
        let date = new Date();
        let id = doc.find(".lib--notification .part_ui_notification").length;
        let uid = date.getTime() + date.getMilliseconds() + id;

        let tpl = '<div class="part_ui_notification state--'+ data.state +'" data-id="'+uid+'"><button class="elm_close icon--close" data-notification-close></button>' +
            '<div class="wrp_part_body row--flex"><div class="col"><strong class="elm_title">'+ data.title +'</strong></div><div class="col"><span class="elm_text">'+ data.text +'</span></div></div>' +
            '</div>';

        // doc.find(".lib--notification .part_ui_notification").remove();
        if (!doc.find(".lib--notification").length) {
            body.append('<div class="lib--notification"></div>');
        }

        doc.find(".lib--notification").append(tpl);

        setTimeout(function(){
            doc.find(".lib--notification .part_ui_notification").filter('[data-id="'+uid+'"]').addClass("is--animate-in").show();
        },500*(id/2));

        if (data.autohide) {
            setTimeout(function(){
                fn_lib_notification_hide(uid);
            },14000*((id+1)/2));
        }
    }

    function fn_lib_notification_hide(id) {
        doc.find(".lib--notification .part_ui_notification").filter('[data-id="'+id+'"]').addClass("is--animate-out");
        setTimeout(function(){
            doc.find(".lib--notification .part_ui_notification").filter('[data-id="'+id+'"]').slideUp(300);
            setTimeout(function () {
                doc.find(".lib--notification .part_ui_notification").filter('[data-id="'+id+'"]').remove();
                if (doc.find(".lib--notification .part_ui_notification").length < 1) {
                    doc.find(".lib--notification").remove();
                }
            }, 300);
        },400);
    }

    function fn_lib_notification_hide_all() {
        doc.find(".lib--notification .part_ui_notification").addClass("is--animate-out");
        setTimeout(function(){
            doc.find(".lib--notification .part_ui_notification").slideUp(300);
            setTimeout(function () {
                doc.find(".lib--notification .part_ui_notification").remove();
                if (doc.find(".lib--notification .part_ui_notification").length < 1) {
                    doc.find(".lib--notification").remove();
                }
            }, 300);
        },400);
    }

    doc.on("click", "[data-notification-close]", function(){
        let id = $(this).closest(".part_ui_notification").data("id");
        fn_lib_notification_hide(id);
    });

    doc.on("click", "[data-notification-close-all]", function(){
        fn_lib_notification_hide_all();
    });

    return {
        open: fn_lib_notification_show,
        hideAll: fn_lib_notification_hide_all
    };
}
let nl_lib_notification = fn_lib_notification();;
$.lui("comp", ".comp_login", function(selector){
    selector.on("submit", "[data-form-login]", function(e){
        e.preventDefault();
        let form = this;

        // přepsat na ajax

        selector.addClass("is--animated");

        setTimeout(function(){
            body.removeClass("body--loaded").addClass("body--preload");
            form.submit();
        },500);
    });
});;
(function() {
    if (!html.hasClass("ie9")) {
        $.lui("lib_input", ".part_ui_input", function(selector) {
            selector.each(function () {
                let elm = $(this);
                let elm_colorpicker = $(this).filter(".type--colorpicker");

                if (!elm.children("[disabled]").length && !elm.filter("[data-no-validation]").length && elm[0].classList.toString().match(/(type--datepicker|type--colorpicker|state--invalid)/) === null) {
                    elm.validateInput();
                }

                if (elm_colorpicker.length) {
                    $.importStyle(cdnjs.colorpicker_css);
                    $.importScript(cdnjs.colorpicker, function () {
                        elm_colorpicker.find("input").minicolors();
                    });
                }
            });
        });

        doc.on("focus", ".part_ui_input.type--search input", function () {
            $(this).parent().addClass("state--focus");
        });

        doc.on("blur", ".part_ui_input.type--search input", function () {
            if ($(this).val().length === 0) {
                $(this).parent().removeClass("state--focus");
            }
        });

        doc.on("change", ".part_ui_input", function () {
            if ($(this).hasClass("type--number")) {
                if ($(this).find("input").val() === "" || parseInt($(this).find("input").val()) > $(this).find("input").attr("max")) {
                    $(this).find("input").val(1);
                }
            }

            if (!$(this).filter("[data-no-validation]").length && $(this)[0].classList.toString().match(/(type--datepicker|type--colorpicker|state--invalid)/) === null) {
                $(this).validateInput();
            }
        });

        doc.on("click", `.part_ui_input [data-click^="number"]`, function (e) {
            e.preventDefault();
            let elm = $(this);
            let inp = elm.closest(".part_ui_input").find("input");
            let num;

            if (elm.filter('[data-click="number-plus"]').length) {
                num = +inp.val() + inp.data('step');
                if (num <= inp.attr('max')) {
                    inp.val(num).trigger("change");
                }
            }
            if (elm.filter('[data-click="number-minus"]').length) {
                num = +inp.val() - inp.data('step');
                if (num >= inp.attr('min')) {
                    inp.val(num).trigger("change");
                }
            }
        });

        /*
        doc.on("keydown", ".part_ui_input.type--number input", function(e) {
            // Allow: backspace, delete, tab, escape, enter and .
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 || e.keyCode === 16 || (e.keyCode == 65 && e.ctrlKey === true) || (e.keyCode >= 35 && e.keyCode <= 40)) {
                return;
            }
            // Ensure that it is a number and stop the keypress
            if (((e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });
         */
    }
})();;
(function() {
//     doc.on("click", ".part_ui_select select", function(){
//         $(this).parent().toggleClass("state--focus");
//         $(this).one("blur",function() {
//             $(this).parent().removeClass("state--focus");
//         });
//     });
//
    doc.on("change", ".part_ui_select:not(.lib--slimselect) select", function(){
        if ($(this)[0].value === "") {
            $(this).parent().addClass("state--placeholder");
        } else {
            $(this).parent().removeClass("state--placeholder");
        }
    });
})();;


$.lui("lib_ripple", ".part_ui_btn", function(selector) {
    selector.lui_ripple();
});

$.lui("lib_notification", "[data-lib-notification]", function(selector) {
    selector.each(function() {
        let elm = $(this);
        let data = elm.data("lib-notification");
        nl_lib_notification.open({
            autohide: true,
            state: data['state'],
            title: data['title'],
            text: data['text']
        });
    });
});

(function fn_pictureFill() {
    if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
        $.importScript(cdnjs.picturefill);
    }
})();